<template>
  <b-card class="pageFont">
    <b-col cols="12">
      <span class="pageTitle">Outros produtos SESI</span>
    </b-col>
    <b-col cols="12">
      <span class="pageSubtitle">Acesse aqui as outras soluções que o SESI oferece para você e sua empresa</span>
    </b-col>
    <b-overlay
      :show="loading"
      rounded="lg"
      opacity="0.6"
      class="mb-2"
    >
      <div class="d-flex flex-wrap mt-2 ml-1">
        <div
          class="cardIndividual"
          v-for="(product, index) in productsList"
          :key="index"
        >
          <div
            class="cardImage"
            v-if="product.anexo_imagem && product.anexo_imagem.imagem"
          >
            <img class="receivedImage" :src="product.anexo_imagem.imagem" :alt="'Imagem '+product.nome">
          </div>
          <div
            class="cardNoImage"
            v-else
          >
            <span class="cardNoImageText">
              {{ product.nome }}
            </span>
          </div>
          <div class="p-2 text-center">
            <div class="cardTitle">
              {{ product.nome }}
            </div>
            <div
              class="cardSubtitle"
            >
              <div
                class="text-center"
              >
                {{ product.descricao }}
              </div>
            </div>
            <div class="mt-1">
              <b-link
                :href="product.anexo_informacao.arquivo"
                target="_blank"
              >
                <b-button
                  variant="outline-primary"
                  class="btn-icon cardFirstButton"
                >
                  <span>Quero saber mais</span>
                </b-button>
              </b-link>
            </div>
            <div>
              <b-link
                :href="product.anexo_pagina.arquivo"
                target="_blank"
              >
                <b-button
                  variant="custom"
                  class="btn-icon cardSecondButton"
                >
                  <span>Acessar minha conta</span>
                </b-button>
              </b-link>
            </div>
          </div>
        </div>
      </div>
      <div
          v-if="!productsList || productsList.length == 0"
          class="text-center mb-n1"
        >
          <span>Nenhum produto foi encontrado.</span>
        </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton, BLink, BOverlay } from 'bootstrap-vue'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    BCard, BCol, BRow, BButton, BLink, BOverlay
  },
  data() {
      return {
        productsList: null,
        loading: true,
      }
  },

  mounted() {
    this.getProductCardsList();
  },

  methods: {
    async getProductCardsList() {
      await this.$http.get(this.$api.OutrosProdutos.getAndSaveProduct)
      .then((response) => {
        this.productsList = response.data;
        this.loading = false;
      }).catch(() => {
        modalGenericModel(
          'Ocorreu um erro!',
          'Ocorreu um erro de conexão com nossa base de dados. Por favor, tente novamente mais tarde ou contate nosso suporte.',
          require('@/assets/custom-icons/cora-icons/error.png'),
          'Ok'
        ).then(() => {
          this.$router.push(
            {
              name: 'bem-vindo'
            }
          );
        });
      });
    },
  }
}

</script>

<style scoped>
  .pageFont{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  .pageTitle{
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #5E5873;
  }
  .pageSubtitle{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5873;
  }
  .cardIndividual{
    padding: 0;
    margin-right: 25px;
    margin-bottom: 25px;
    box-shadow: 4px 4px 16px rgba(216, 214, 222, 0.8);
    border-radius: 6px;
    width: 334px;
  }
  .cardImage{
    height: 273px;
    border-radius: 6px 6px 0 0;
  }
  .cardNoImage{
    height: 273px;
    width: 100%;
    border-radius: 6px 6px 0 0;
    background: linear-gradient(45deg, rgba(39, 114, 192, 0.65) 0%, rgba(105, 179, 255, 0.65) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardNoImageText{
    width: 250px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .receivedImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cardTitle{
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #5E5873;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .cardSubtitle{
    font-weight: 400;
    font-size: 16px;
    color: #6E6B7B;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    margin-top: 8px;
  }
  .cardFirstButton{
    height: 37px;
    width:100%;
  }
  .cardSecondButton{
    margin-top: 10px;
    height: 37px;
    width:100%;
  }
  @media (max-width: 400px) {
    .cardIndividual{
      margin-right: 0;
      width: 300px;
    }
    .cardNoImageText{
      width: 200px;
    }
  }

  @media (max-width: 350px) {
    .cardIndividual{
      width: 260px;
    }
    .cardNoImageText{
      width: 160px;
    }
  }

  @media (max-width: 331px) {
    .cardIndividual{
      width: 230px;
    }
    .cardNoImageText{
      width: 150px;
    }
  }

</style>
